import { type FC, useEffect } from 'react';
import type { PageProps } from 'gatsby';
import { CustomProvider } from 'rsuite';

import { Header } from '../Header';
import { Footer } from '../Footer';
import IncidentMessage from '../IncidentMessage';
import ErrorBoundary from '../../common/ErrorBoundary';
import { env } from '../../utils/env';
import { MainLayoutContainer } from '../../styles/layout';
import { ThemeColorsDark } from '../../styles/colors';
import { Container } from './styles';
import { useTheme } from '../../context/theme';
import { BrokerContextProvider } from '../../context/broker';
import Autoslider from '../Autosliider';
import BrokerPanel from '../BrokerPanel';
import Offline from '../../common/Offline';
import useConnectionStatus from '../../hooks/useConnectionStatus';

declare global {
  interface Window {
    $crisp: unknown[];
    CRISP_WEBSITE_ID?: string;
  }
}

const MainLayout: FC<PageProps> = ({ children, location }) => {
  const { theme } = useTheme();
  const { online } = useConnectionStatus();

  useEffect(() => {
    window.feedspace?.init();
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = env.crispWebsiteId;
  }, []);

  if (!online) {
    return <Offline />;
  }

  return (
    <ErrorBoundary>
      <ThemeColorsDark />
      <CustomProvider theme={theme}>
        <BrokerContextProvider>
          <MainLayoutContainer>
            <IncidentMessage />
            {location.pathname !== '/' && (
              <>
                <Header />
                <Autoslider />
                <BrokerPanel />
              </>
            )}
            <Container>{children}</Container>
            <Footer />
          </MainLayoutContainer>
        </BrokerContextProvider>
      </CustomProvider>
    </ErrorBoundary>
  );
};

export default MainLayout;
